import React from 'react';

import { FaCoins, FaGooglePay, FaRegCreditCard } from 'react-icons/fa';
import { FaMobileRetro } from 'react-icons/fa6';
import { SiApplepay } from 'react-icons/si';
import { shallowEqual, useSelector } from 'react-redux';

import { RootState } from '../../redux/store';
import { getUniqueKey } from '../../util/utils';

import styles from './PaymentOptions.module.scss';
const PaymentOptionsComponent: React.FC<{ paymentOptions: string[]; framed?: boolean }> = ({
    paymentOptions,
    framed = false,
}) => {
    const extraPaymentOptions = useSelector((state: RootState) => state.app.paymentOptions, shallowEqual);

    return (
        <div className={framed ? styles.framed : styles.icons}>
            {paymentOptions.map((payment) => {
                if (payment === 'cash') {
                    return <FaCoins key={getUniqueKey()} />;
                }
                if (payment === 'cardterminal') {
                    return <FaMobileRetro color="black" style={{ marginLeft: 0 }} key={getUniqueKey()} />;
                }
                if (payment === 'paypal') {
                    return <img key={getUniqueKey()} alt="paypal" src={`assets/paypal.png`} />;
                }
                if (payment === 'card') {
                    return <FaRegCreditCard color="black" key={getUniqueKey()} />;
                }
                if (payment === 'applepay' && extraPaymentOptions && extraPaymentOptions.apple) {
                    const padding = framed ? 6 : 0;
                    return (
                        <SiApplepay
                            style={{ height: 28, width: 28, marginBottom: 0, padding }}
                            color="black"
                            key={getUniqueKey()}
                        />
                    );
                }
                if (payment === 'googlepay' && extraPaymentOptions && extraPaymentOptions.google) {
                    return <FaGooglePay color="black" key={getUniqueKey()} />;
                }
                if (payment === 'giropay') {
                    return (
                        <img className={styles.giro} key={getUniqueKey()} alt="giropay" src={`assets/giropay.svg`} />
                    );
                }
                return null;
            })}
        </div>
    );
};

export default PaymentOptionsComponent;
